var pug = require("!../../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (config, css, locations, parseInt, search) {
      pug_html = pug_html + "\u003Cform" + (pug.attr("class", pug.classes([css.libcal_select], [true]), false, true)+pug.attr("id", css.libcal_select, true, true)) + "\u003E\u003Clabel\u003ESelect a Calendar\u003Cselect name=\"location\"\u003E";
if (config.all) {
pug_html = pug_html + "\u003Coption value=\"\"\u003EAll Locations - Weekly\u003C\u002Foption\u003E";
}
// iterate locations
;(function(){
  var $$obj = locations;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var location = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", location.lid, true, true)+pug.attr("selected", location.lid === parseInt(search.get('location'), 10), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = location.name + (config.all ? " - Monthly" : "")) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var location = $$obj[pug_index0];
pug_html = pug_html + "\u003Coption" + (pug.attr("value", location.lid, true, true)+pug.attr("selected", location.lid === parseInt(search.get('location'), 10), true, true)) + "\u003E" + (pug.escape(null == (pug_interp = location.name + (config.all ? " - Monthly" : "")) ? "" : pug_interp)) + "\u003C\u002Foption\u003E";
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fselect\u003E\u003C\u002Flabel\u003E\u003Cinput type=\"submit\" value=\"View\"\u003E\u003C\u002Fform\u003E";
    }.call(this, "config" in locals_for_with ?
        locals_for_with.config :
        typeof config !== 'undefined' ? config : undefined, "css" in locals_for_with ?
        locals_for_with.css :
        typeof css !== 'undefined' ? css : undefined, "locations" in locals_for_with ?
        locals_for_with.locations :
        typeof locations !== 'undefined' ? locations : undefined, "parseInt" in locals_for_with ?
        locals_for_with.parseInt :
        typeof parseInt !== 'undefined' ? parseInt : undefined, "search" in locals_for_with ?
        locals_for_with.search :
        typeof search !== 'undefined' ? search : undefined));
    ;;return pug_html;};
module.exports = template;