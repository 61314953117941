// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../svg/mixin_form_select.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bgsu_libcal_JbixM{box-sizing:border-box;text-align:left;font-variant-numeric:tabular-nums;line-height:1.5rem;padding-bottom:2rem;position:relative}.bgsu_libcal_JbixM *,.bgsu_libcal_JbixM *::before,.bgsu_libcal_JbixM *::after{box-sizing:inherit}.bgsu_libcal_JbixM :is(audio,canvas,iframe,img,svg,video){vertical-align:middle}.bgsu_libcal_head_vSCe1{font:normal normal 400 1.5rem/1 "kepler-std-display","Century Schoolbook","New Century Schoolbook","Georgia","Palatino","Bookman",serif;-webkit-font-smoothing:antialiased;margin:.5rem 0}.bgsu_libcal_body_nMYEB>:first-child:not(.bgsu_libcal_date_icsWb){font-size:1.25rem;font-weight:300}.bgsu_libcal_date_icsWb{font-weight:400;float:left;min-width:8rem}.bgsu_libcal_time_Dku0f{margin-bottom:.5rem;min-width:8rem;overflow:hidden}.bgsu_libcal_desc_AbOhf{float:right;margin-left:12px}.bgsu_libcal_foot_OlzlP{position:absolute;right:0;bottom:0}.bgsu_libcal_select__lldk{box-sizing:border-box;text-align:left}.bgsu_libcal_select__lldk *,.bgsu_libcal_select__lldk *::before,.bgsu_libcal_select__lldk *::after{box-sizing:inherit}.bgsu_libcal_select__lldk :is(audio,canvas,iframe,img,svg,video){vertical-align:middle}.bgsu_libcal_select__lldk label{display:block;margin:0;padding:0}.bgsu_libcal_select__lldk select{background-color:#fff;border:1px solid #ced4da;border-radius:.25rem;color:#212529;font:inherit;letter-spacing:inherit;margin:0;padding:.25rem .375rem;-webkit-appearance:none;appearance:none;background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___});background-position:right .375rem center;background-repeat:no-repeat;background-size:1rem;padding-right:1.75rem}.bgsu_libcal_select__lldk select[disabled]{background-color:#e9ecef;opacity:1}.bgsu_libcal_select__lldk select[multiple]{background-image:none;padding-right:.375rem}.bgsu_libcal_select__lldk input[type=submit]{display:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"libcal": `bgsu_libcal_JbixM`,
	"libcal_head": `bgsu_libcal_head_vSCe1`,
	"libcal_body": `bgsu_libcal_body_nMYEB`,
	"libcal_date": `bgsu_libcal_date_icsWb`,
	"libcal_time": `bgsu_libcal_time_Dku0f`,
	"libcal_desc": `bgsu_libcal_desc_AbOhf`,
	"libcal_foot": `bgsu_libcal_foot_OlzlP`,
	"libcal_select": `bgsu_libcal_select__lldk`
};
export default ___CSS_LOADER_EXPORT___;
