var pug = require("!../../node_modules/pug-runtime/index.js");

function template(locals) {var pug_html = "", pug_mixins = {}, pug_interp;;
    var locals_for_with = (locals || {});
    
    (function (DOMParser, config, css, locations, parseInt) {
      pug_mixins["desc"] = pug_interp = function(desc){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (desc) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_desc], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = new DOMParser().parseFromString(desc, "text/html").body.textContent) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
}
};
pug_mixins["link"] = pug_interp = function(href, text, prefix){
var block = (this && this.block), attributes = (this && this.attributes) || {};
if (href) {
pug_html = pug_html + "\u003Ca" + (pug.attr("href", href, true, true)+pug.attr("aria-label", `${prefix} - ${text}`, true, true)) + "\u003E\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = text) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E\u003C\u002Fa\u003E";
}
else {
pug_html = pug_html + "\u003Cstrong\u003E" + (pug.escape(null == (pug_interp = text) ? "" : pug_interp)) + "\u003C\u002Fstrong\u003E";
}
};
pug_mixins["times"] = pug_interp = function(times){
var block = (this && this.block), attributes = (this && this.attributes) || {};
switch (times.status){
case "24hours":
pug_html = pug_html + "\u003Cem\u003EOpen 24 Hours\u003C\u002Fem\u003E";
  break;
case "ByApp":
pug_html = pug_html + "\u003Cem\u003EBy Appointment\u003C\u002Fem\u003E";
  break;
case "closed":
pug_html = pug_html + "\u003Cem\u003EClosed\u003C\u002Fem\u003E";
  break;
case "text":
pug_html = pug_html + (null == (pug_interp = times.text) ? "" : pug_interp);
  break;
default:
if (times.hours) {
// iterate times.hours
;(function(){
  var $$obj = times.hours;
  if ('number' == typeof $$obj.length) {
      for (var pug_index0 = 0, $$l = $$obj.length; pug_index0 < $$l; pug_index0++) {
        var hours = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv\u003E" + (pug.escape(null == (pug_interp = `${hours.from} - ${hours.to}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var pug_index0 in $$obj) {
      $$l++;
      var hours = $$obj[pug_index0];
pug_html = pug_html + "\u003Cdiv\u003E" + (pug.escape(null == (pug_interp = `${hours.from} - ${hours.to}`) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
  break;
}
if (times.note) {
pug_html = pug_html + "\u003Cdiv\u003E\u003Cem\u003E" + (pug.escape(null == (pug_interp = times.note) ? "" : pug_interp)) + "\u003C\u002Fem\u003E\u003C\u002Fdiv\u003E";
}
};
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal], [true]), false, true)) + "\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_head], [true]), false, true)) + "\u003E";
if (config.title) {
if (config.link && !config.mode) {
pug_mixins["link"](config.link, config.title);
}
else {
pug_html = pug_html + (pug.escape(null == (pug_interp = config.title) ? "" : pug_interp));
}
}
else
if (config.mode === "week") {
pug_html = pug_html + "This Week’s Hours";
}
else {
pug_html = pug_html + "Today’s Hours";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_body], [true]), false, true)) + "\u003E";
// iterate locations
;(function(){
  var $$obj = locations;
  if ('number' == typeof $$obj.length) {
      for (var pug_index1 = 0, $$l = $$obj.length; pug_index1 < $$l; pug_index1++) {
        var location = $$obj[pug_index1];
if (config.location) {
if (location.lid === parseInt(config.location, 10)) {
if (config.mode === "week") {
// iterate location.weeks.shift()
;(function(){
  var $$obj = location.weeks.shift();
  if ('number' == typeof $$obj.length) {
      for (var name = 0, $$l = $$obj.length; name < $$l; name++) {
        var day = $$obj[name];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_date], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_time], [true]), false, true)) + "\u003E";
pug_mixins["times"](day.times);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var name in $$obj) {
      $$l++;
      var day = $$obj[name];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_date], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_time], [true]), false, true)) + "\u003E";
pug_mixins["times"](day.times);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_time], [true]), false, true)) + "\u003E";
pug_mixins["times"](location.times);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
if ((!config.title || config.mode) && config.link) {
pug_mixins["link"](
              config.link,
              "More Hours",
              `${config.title || ""} ${location.name}`.trim()
            );
}
}
}
else
if (config.all || location.category === "library" || location.desc) {
pug_html = pug_html + "\u003Cdiv\u003E";
if (config.mode !== "home" || location.category !== "library") {
pug_html = pug_html + "\u003Cdiv\u003E";
pug_mixins["link"](
                location.url,
                location.name,
                (config.title
                  ? config.title
                  : config.mode === "week"
                    ? "This Week’s Hours"
                    : "Today’s Horus"
                )
              );
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_mixins["desc"](location.desc);
if (location.times.status !== "not-set") {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_time], [true]), false, true)) + "\u003E";
pug_mixins["times"](location.times);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
      }
  } else {
    var $$l = 0;
    for (var pug_index1 in $$obj) {
      $$l++;
      var location = $$obj[pug_index1];
if (config.location) {
if (location.lid === parseInt(config.location, 10)) {
if (config.mode === "week") {
// iterate location.weeks.shift()
;(function(){
  var $$obj = location.weeks.shift();
  if ('number' == typeof $$obj.length) {
      for (var name = 0, $$l = $$obj.length; name < $$l; name++) {
        var day = $$obj[name];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_date], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_time], [true]), false, true)) + "\u003E";
pug_mixins["times"](day.times);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
      }
  } else {
    var $$l = 0;
    for (var name in $$obj) {
      $$l++;
      var day = $$obj[name];
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_date], [true]), false, true)) + "\u003E" + (pug.escape(null == (pug_interp = name) ? "" : pug_interp)) + "\u003C\u002Fdiv\u003E\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_time], [true]), false, true)) + "\u003E";
pug_mixins["times"](day.times);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }
  }
}).call(this);

}
else {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_time], [true]), false, true)) + "\u003E";
pug_mixins["times"](location.times);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
if ((!config.title || config.mode) && config.link) {
pug_mixins["link"](
              config.link,
              "More Hours",
              `${config.title || ""} ${location.name}`.trim()
            );
}
}
}
else
if (config.all || location.category === "library" || location.desc) {
pug_html = pug_html + "\u003Cdiv\u003E";
if (config.mode !== "home" || location.category !== "library") {
pug_html = pug_html + "\u003Cdiv\u003E";
pug_mixins["link"](
                location.url,
                location.name,
                (config.title
                  ? config.title
                  : config.mode === "week"
                    ? "This Week’s Hours"
                    : "Today’s Horus"
                )
              );
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_mixins["desc"](location.desc);
if (location.times.status !== "not-set") {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_time], [true]), false, true)) + "\u003E";
pug_mixins["times"](location.times);
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
    }
  }
}).call(this);

pug_html = pug_html + "\u003C\u002Fdiv\u003E";
if (!config.location && (!config.title || config.mode) && config.link) {
pug_html = pug_html + "\u003Cdiv" + (pug.attr("class", pug.classes([css.libcal_foot], [true]), false, true)) + "\u003E";
pug_mixins["link"](config.link, "All Library Hours");
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
}
pug_html = pug_html + "\u003C\u002Fdiv\u003E";
    }.call(this, "DOMParser" in locals_for_with ?
        locals_for_with.DOMParser :
        typeof DOMParser !== 'undefined' ? DOMParser : undefined, "config" in locals_for_with ?
        locals_for_with.config :
        typeof config !== 'undefined' ? config : undefined, "css" in locals_for_with ?
        locals_for_with.css :
        typeof css !== 'undefined' ? css : undefined, "locations" in locals_for_with ?
        locals_for_with.locations :
        typeof locations !== 'undefined' ? locations : undefined, "parseInt" in locals_for_with ?
        locals_for_with.parseInt :
        typeof parseInt !== 'undefined' ? parseInt : undefined));
    ;;return pug_html;};
module.exports = template;